<script>

    // import components
    import Background from "./Background.svelte";

    // if orientation changes, reload page
    window.addEventListener("orientationchange", function(){
        window.location.reload();
    }); 
    
</script>


<!-- Animated Background -->
<Background/>

<!-- Company -->
<div class="container">
    <h1>Bili Kameni</h1>
    <p>We provide advanced data collection, analysis, and visualization services.</p>
    <div class="contact">
        <p>Maidan Nezalezhnosti, 2, Kyiv, Ukraine</p>
        <p>info@bilikameni.com</p>
    </div>
</div>


<style>

    h1 {
        font-size: 2.0rem;
        margin: 0;
        text-align: center;
        font-weight: 300;
    }
    
    p {
        font-size: 1.2rem;
        text-align: center;
        margin: 0px;
        margin-top: 0.5rem;
        font-weight: 200;
    }

    .container {
        z-index: 1;
        position: absolute;
        top: 33.33%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0px 1.5rem;
        width: 80%;
    }
    
    .contact {
        width: 100%;
        margin-top: 3rem;
        align-items: center;
    }

    .contact p {
        font-size: 0.9rem;
        margin-bottom: 1.0rem;
    }    

</style>